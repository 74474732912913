* {
  font-family: "Roboto", sans-serif;
  color: rgb(194, 193, 255);
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgb(31, 30, 36);
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #757de8;
  border-radius: 20px;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 400px;
  /* padding: 40px 40px 400px 40px; */
}

.navbar {
  display: flex;
  justify-content: space-between;
  position: fixed;
  align-items: center;
  background-color: rgb(31, 30, 36);
  width: 100vw;
  height: 70px;
  top: 0;
  z-index: 1;
}

.navbarLeft {
  display: flex;
  align-items: center;
}

.navbarRight {
  display: flex;
  align-content: center;
  margin: 0 10px 0 10px;
  padding: 0;
}

.navbarInitials {
  font-family: "Crimson Text", serif;
  font-size: 32px;
  /* color: #1c85e8; */
  /* color: #3f51b5; */
  color: #757de8;
  margin: 0 10px 0 20px;
  padding: 0;
}

.navbarName {
  margin: 0 20px 0 10px;
  padding: 0;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.aboutName {
  font-size: 32px;
  font-weight: bold;
  margin: 15px;
}

.aboutNameBlue {
  /* color: #1c85e8; */
  /* color: #3f51b5; */
  color: #757de8;
}

.aboutH2 {
  margin: 2px;
}

.projects {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  height: 100vh;
  min-height: 600px;
  max-width: 1000px;
}

.topSpace {
  top: 0;
  height: 100px;
}

@media only screen and (max-width: 970px) {
  .app {
    height: auto;
  }

  .projects {
    height: auto;
  }

  .topSpace {
    top: 0;
    height: 70px;
  }
}

@media only screen and (max-width: 411px) {
  .navbarName {
    font-size: 16px;
  }

  .aboutName {
    font-size: 26px;
  }

  .aboutH2 {
    font-size: 16px;
  }
}
