.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  min-height: 500px;
}

.modal {
  background-color: rgb(52, 51, 59);
  display: flex;
  border-radius: 5px;
  box-shadow: 5px 5px 20px black;
  height: 70%;
  width: auto;
  padding: 5px;
  max-width: 900px;
}

.modalImg {
  border-radius: 10px;
  width: auto;
  height: 100%;
}

.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  min-height: 500px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 400px;
  align-items: center;
  padding: 0 10px 0 10px;
}

.modalTitle {
  margin: -10px 0 15px 0;
}

.modalClose {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0 -20px 0 0;
}

.modalContentText {
  height: 100%;
  width: 100%;
  white-space: pre-line;
  overflow-y: scroll;
  padding: 0 5px 0 5px;
}

@media only screen and (max-width: 620px) {
  .modalImg {
    display: none;
  }
}

@media only screen and (max-width: 460px) {
  .modal {
    width: 90%;
  }

  .modalContainer {
    /* min-height: none; */
    width: auto;
  }
}
