.card {
  background-color: rgb(52, 51, 59);
  border-radius: 5px;
  box-shadow: 5px 5px 20px black;
  width: 300px;
  margin: 20px 10px 40px 10px;
}

.cardHeader {
  padding: 0 10px 0 10px;
}

.cardChips {
  margin: 0px 5px 0px 5px;
}

.cardChip {
  margin: 2px;
}

.cardDescription {
  margin: 0px 10px 0px 10px;
}

.cardButtons {
  display: flex;
  justify-content: space-around;
  margin: 5px;
}

.icon {
  margin: 0 10px 0 0;
}
